
import {
  IonContent,
  IonPage,
  IonButton,
  IonIcon,
  IonCheckbox,
} from "@ionic/vue";
import {
  atCircleOutline,
  phonePortraitOutline,
  eyeOutline,
  eyeOffOutline,
  logoFacebook,
  logoGoogle,
  logoApple,
} from "ionicons/icons";
import { defineComponent, ref, reactive, computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import { useLocalStorage } from "@/libs/localStorage";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import httpApp from "@/libs/http";
import settingsApp from "@/libs/settings";
import sessionApp from "@/libs/session";
import cartApp from "@/libs/cart";
import compareApp from "@/libs/comparelist";
import wishlistApp from "@/libs/wishlist";
import notificationApp from "@/libs/notification";

import useVuelidate from "@vuelidate/core";
import { required, requiredIf, email } from "@vuelidate/validators";

import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";

import { event as gtagEvent } from "vue-gtag";

export default defineComponent({
  name: "Login",
  setup() {
    const { t, te } = useI18n({ useScope: "global" });
    const router = useRouter();
    const storeX = useStore();
    const { getLocalStorageData, setLocalStorageData } = useLocalStorage();
    const { createLoading, openToast, setCookie, registerDevice } =
      useGlobalFunctions();

    const icons = reactive({
      atCircleOutline,
      phonePortraitOutline,
      eyeOutline,
      eyeOffOutline,
      logoFacebook,
      logoGoogle,
      logoApple,
    });

    const domainOriginUrl = window.location.origin;
    const isAE = domainOriginUrl.includes("ae.yallatager");
    const countryCode = ref(isAE ? "AE" : "EG");

    const setReferralPath = () => {
      let referralUrl = getLocalStorageData("referralUrl");
      if (!referralUrl) {
        if (router.options.history.state.back) {
          referralUrl = router.options.history.state.back as string;
        } else {
          referralUrl = "/" + storeX.state.storeCode + "/account";
        }
        setLocalStorageData({ referralUrl: referralUrl });
      }
    };

    const showRememberMe =
      getLocalStorageData("persistentEnabled") == 1 &&
      getLocalStorageData("persistentRememberEnabled") == 1;

    const signIn = ref({
      usernameemail: "",
      usernamemobile: "",
      useMobile: false,
      mobileValidated: false,
      password: "",
      showPassword: false,
      showRememberMe,
      persistent_remember_me: false,
    });

    const loginData = computed(() => {
      const loginDataObj = {
        usernameemail: signIn.value.usernameemail,
        usernamemobile: signIn.value.usernamemobile,
        password: signIn.value.password,
        persistent_remember_me: signIn.value.persistent_remember_me,
      };
      if (!signIn.value.useMobile) {
        delete loginDataObj.usernamemobile;
      } else {
        delete loginDataObj.usernameemail;
      }

      return loginDataObj;
    });

    const onTelInput = (phone: any, phoneObject: any) => {
      let value = phone;
      if (phone instanceof InputEvent) {
        value = phone.data;
      }
      if (value && /^[a-zA-Z]+$/.test(value)) {
        signIn.value.usernameemail = value;
        signIn.value.usernamemobile = null;
        signIn.value.useMobile = false;
        setTimeout(() => {
          const ele = document.querySelectorAll(
            ".userNameEmail"
          )[0] as HTMLInputElement;
          if (ele) {
            ele.focus();
          }
        }, 100);
        return;
      }
      if (phoneObject) {
        if (Object.prototype.hasOwnProperty.call(phoneObject, "number")) {
          signIn.value.usernamemobile = phoneObject.number;
        }
        if (Object.prototype.hasOwnProperty.call(phoneObject, "valid")) {
          signIn.value.mobileValidated = phoneObject.valid;
        }
      }
    };

    const chcekEmailPhone = ($event) => {
      const value = $event.target.value;
      if (value.startsWith("01") || value.startsWith("+201")) {
        signIn.value.usernamemobile = value.startsWith("01")
          ? value.substring(1)
          : value.substring(3);
        signIn.value.usernameemail = "";
        signIn.value.useMobile = true;
        setTimeout(() => {
          const ele = document.querySelectorAll(
            ".vti__input"
          )[0] as HTMLInputElement;
          if (ele) {
            ele.focus();
          }
        }, 100);
      }
    };

    const signInRules = {
      usernameemail: {
        required: requiredIf(() => {
          return !signIn.value.useMobile ? true : false;
        }),
        email,
      },
      usernamemobile: {
        required: requiredIf(() => {
          return signIn.value.useMobile ? true : false;
        }),
      },
      password: {
        required,
      },
    };
    const vSignIn = useVuelidate(signInRules, signIn) as any;

    const signInAction = async (event) => {
      event.preventDefault();
      vSignIn.value.$touch();
      if (vSignIn.value.$invalid) {
        return false;
      }
      if (signIn.value.useMobile && !signIn.value.mobileValidated) {
        return false;
      }
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("login"), loginData.value)
        .then((resp: any) => {
          if (resp.success) {
            gtagEvent("login", { method: "Default" });
            if (signIn.value.persistent_remember_me && resp.rememberMeCookie) {
              setCookie(
                resp.rememberMeCookie.name,
                resp.rememberMeCookie.value,
                resp.rememberMeCookie.lifeTime * 1000
              );
            }

            const getCustomerDataPromise = sessionApp.getCustomerData();
            getCustomerDataPromise.then((customerDataResp: any) => {
              loading.dismiss();
              storeX.commit("setCustomerData", customerDataResp);
              registerDevice();
              let referalUrl = getLocalStorageData("referralUrl");
              const referalUrlArr =
                getLocalStorageData("referralUrl").split("/");
              const oldStoreCode = referalUrlArr[1];
              if (referalUrlArr[1] != storeX.state.storeCode) {
                referalUrlArr[1] = storeX.state.storeCode;
                referalUrl =
                  referalUrlArr.join("/") + "?from_store=" + oldStoreCode;
              }
              router.replace(referalUrl);
              setLocalStorageData({ referralUrl: null });
              settingsApp.getVendorData();
            });
            cartApp.getQuoteItems();
            wishlistApp.getWishlistItems();
            compareApp.getProductsToCompare();
            notificationApp.getNotifications(1);
            notificationApp.getNewNotificationsCount(false);
          } else {
            loading.dismiss();
            storeX.commit("setCustomerData", null);
            if (te("apiMsgs." + resp.error.code)) {
              openToast(t("apiMsgs." + resp.error.code), "danger");
            } else {
              openToast(resp.error.message, "danger");
            }
          }
        })
        .catch((error) => {
          console.log(error, "login error");
          loading.dismiss();
        });
    };

    const showForgotPassword = ref(false);

    const forgotPassword = ref({
      email: "",
      mobileNo: "",
      useMobile: false,
      mobileValidated: false,
    });

    const forgotPasswordData = computed(() => {
      const forgotPasswordDataObj = {
        email: forgotPassword.value.email,
        mobileNo: forgotPassword.value.mobileNo,
      };
      if (forgotPassword.value.useMobile) {
        delete forgotPasswordDataObj.email;
      } else {
        delete forgotPasswordDataObj.mobileNo;
      }

      return forgotPasswordDataObj;
    });

    const onTelInputForgotPassword = (phone: any, phoneObject: any) => {
      if (phoneObject) {
        if (Object.prototype.hasOwnProperty.call(phoneObject, "number")) {
          forgotPassword.value.mobileNo = phoneObject.number;
        }
        if (Object.prototype.hasOwnProperty.call(phoneObject, "valid")) {
          forgotPassword.value.mobileValidated = phoneObject.valid;
        }
      }
    };

    const forgotPasswordRules = {
      email: {
        required: requiredIf(() => {
          return !forgotPassword.value.useMobile ? true : false;
        }),
        email,
      },
      mobileNo: {
        required: requiredIf(() => {
          return forgotPassword.value.useMobile ? true : false;
        }),
      },
    };
    const vforgotPassword = useVuelidate(
      forgotPasswordRules,
      forgotPassword
    ) as any;

    const forgotPasswordAction = async (event) => {
      event.preventDefault();
      vSignIn.value.$touch();
      vforgotPassword.value.$touch();
      if (vforgotPassword.value.$invalid) {
        return false;
      }
      if (
        forgotPassword.value.useMobile &&
        !forgotPassword.value.mobileValidated
      ) {
        return false;
      }
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      httpApp
        .sendPostRequest(
          settingsApp.getEndpointUrl("forgotPassword"),
          forgotPasswordData.value
        )
        .then((resp: any) => {
          if (resp.success) {
            openToast(t("login.forgotPasswordMsg"), "success");
            showForgotPassword.value = false;
            loading.dismiss();
          } else {
            loading.dismiss();
            if (te("apiMsgs." + resp.error.code)) {
              openToast(t("apiMsgs." + resp.error.code), "danger");
            } else {
              te("setCustomerData", null);
              openToast(resp.error.message, "danger");
            }
          }
        })
        .catch((error) => {
          loading.dismiss();
          console.log(error);
        });
    };

    const navSignUp = () => {
      router.push("/account/signup");
    };

    const socialEnabled = ref(false);
    const facebookLoginUrl = ref("");
    const googleLoginUrl = ref("");
    const appleLoginUrl = ref("");
    const domainLangUrl = window.location.origin + "/" + storeX.state.storeCode;
    const getFacebookAuthUrl = () => {
      const callbackUrl = domainLangUrl + "/account/socialconnect/facebook";
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getFacebookAuthUrl"), {
          callback_url: callbackUrl,
        })
        .then((resp: any) => {
          if (resp.success) {
            facebookLoginUrl.value = resp.url;
            socialEnabled.value = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getGoogleAuthUrl = () => {
      const callbackUrl = domainLangUrl + "/account/socialconnect/google";
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getGoogleAuthUrl"), {
          callback_url: callbackUrl,
        })
        .then((resp: any) => {
          if (resp.success) {
            googleLoginUrl.value = resp.url;
            socialEnabled.value = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getAppleAuthUrl = () => {
      const callbackUrl = domainLangUrl + "/account/socialconnect/apple";
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getAppleAuthUrl"), {
          callback_url: callbackUrl,
        })
        .then((resp: any) => {
          if (resp.success) {
            appleLoginUrl.value = resp.url;
            socialEnabled.value = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const ionViewWillEnter = () => {
      getFacebookAuthUrl();
      getGoogleAuthUrl();
      getAppleAuthUrl();
      setReferralPath();
    };

    return {
      t,
      icons,
      ionViewWillEnter,
      signInAction,
      forgotPasswordAction,
      navSignUp,
      countryCode,
      signIn,
      vSignIn,
      forgotPassword,
      vforgotPassword,
      onTelInput,
      onTelInputForgotPassword,
      showForgotPassword,
      chcekEmailPhone,
      socialEnabled,
      facebookLoginUrl,
      googleLoginUrl,
      appleLoginUrl,
    };
  },
  components: {
    IonContent,
    IonPage,
    MainHeader,
    MainFooter,
    VueTelInput,
    IonButton,
    IonIcon,
    IonCheckbox,
  },
});
