import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main pages" }
const _hoisted_2 = { class: "page-content pt-100 pb-100" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-xl-8 col-lg-10 col-md-12 m-auto" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-xl-8 col-lg-10 col-md-12 m-auto" }
const _hoisted_8 = {
  key: 0,
  class: "login_wrap widget-taber-content background-white"
}
const _hoisted_9 = { class: "padding_eight_all bg-white p-15" }
const _hoisted_10 = { class: "heading_s1" }
const _hoisted_11 = { class: "mb-5" }
const _hoisted_12 = { class: "mb-30" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-sm-12" }
const _hoisted_15 = {
  key: 0,
  class: "form-group"
}
const _hoisted_16 = ["placeholder"]
const _hoisted_17 = {
  key: 0,
  class: "form-group"
}
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "col-sm-12" }
const _hoisted_20 = { class: "form-group password" }
const _hoisted_21 = ["type", "placeholder"]
const _hoisted_22 = { class: "login_footer form-group mb-50" }
const _hoisted_23 = {
  key: 0,
  class: "chek-form"
}
const _hoisted_24 = { class: "custome-checkbox" }
const _hoisted_25 = {
  class: "form-check-label",
  for: "exampleCheckbox1"
}
const _hoisted_26 = { class: "form-group" }
const _hoisted_27 = { class: "row" }
const _hoisted_28 = { class: "col-lg-6 col-md-6" }
const _hoisted_29 = {
  type: "submit",
  class: "btn btn-heading btn-block hover-up",
  name: "login"
}
const _hoisted_30 = {
  key: 1,
  class: "login_wrap widget-taber-content background-white"
}
const _hoisted_31 = { class: "padding_eight_all bg-white p-15" }
const _hoisted_32 = { class: "heading_s1" }
const _hoisted_33 = { class: "mb-5" }
const _hoisted_34 = {
  key: 0,
  class: "form-group"
}
const _hoisted_35 = ["placeholder"]
const _hoisted_36 = {
  key: 1,
  class: "form-group"
}
const _hoisted_37 = { class: "row" }
const _hoisted_38 = { class: "col-md-6 col-xs-12" }
const _hoisted_39 = {
  type: "submit",
  class: "btn btn-heading btn-block"
}
const _hoisted_40 = {
  key: 0,
  class: "col-xl-8 col-lg-10 col-md-12 m-auto"
}
const _hoisted_41 = { class: "login_wrap widget-taber-content background-white border-radius-15" }
const _hoisted_42 = { class: "padding_eight_all bg-white p-15" }
const _hoisted_43 = { class: "heading_s1" }
const _hoisted_44 = { class: "mb-5" }
const _hoisted_45 = { class: "socialLoginWrap" }
const _hoisted_46 = ["href"]
const _hoisted_47 = ["href"]
const _hoisted_48 = ["href"]
const _hoisted_49 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_vue_tel_input = _resolveComponent("vue-tel-input")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_MainFooter = _resolveComponent("MainFooter")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_MainHeader),
          _createElementVNode("main", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        (!_ctx.showForgotPassword)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createElementVNode("div", _hoisted_9, [
                                _createElementVNode("div", _hoisted_10, [
                                  _createElementVNode("h1", _hoisted_11, _toDisplayString(_ctx.t("login.login")), 1),
                                  _createElementVNode("p", _hoisted_12, [
                                    _createTextVNode(_toDisplayString(_ctx.t("login.dontHaveAnAccount?")) + " ", 1),
                                    _createElementVNode("a", {
                                      href: "javascript:void(0)",
                                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navSignUp()))
                                    }, _toDisplayString(_ctx.t("login.createHere")), 1)
                                  ])
                                ]),
                                _createElementVNode("form", {
                                  method: "post",
                                  onSubmit: _cache[7] || (_cache[7] = ($event: any) => (_ctx.signInAction($event)))
                                }, [
                                  _createElementVNode("div", _hoisted_13, [
                                    _createElementVNode("div", _hoisted_14, [
                                      (!_ctx.signIn.useMobile)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                            _withDirectives(_createElementVNode("input", {
                                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.signIn.usernameemail) = $event)),
                                              name: "usernameemail",
                                              inputmode: "email",
                                              type: "text",
                                              autocomplete: "email",
                                              placeholder: _ctx.t('login.emailMobile'),
                                              class: _normalizeClass(["form-control userNameEmail", {
                                  'is-invalid':
                                    _ctx.vSignIn.usernameemail.$errors.length,
                                }]),
                                              onKeyup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.chcekEmailPhone($event)))
                                            }, null, 42, _hoisted_16), [
                                              [_vModelText, _ctx.signIn.usernameemail]
                                            ])
                                          ]))
                                        : _createCommentVNode("", true)
                                    ])
                                  ]),
                                  (_ctx.signIn.useMobile)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                        _createElementVNode("div", _hoisted_18, [
                                          _createElementVNode("div", _hoisted_19, [
                                            _createVNode(_component_vue_tel_input, {
                                              onInput: _ctx.onTelInput,
                                              mode: "international",
                                              defaultCountry: _ctx.countryCode,
                                              inputOptions: {
                                  placeholder: _ctx.t('login.emailMobile'),
                                },
                                              value: _ctx.signIn.usernamemobile,
                                              class: _normalizeClass(["form-control", {
  'is-invalid':
    _ctx.vSignIn.usernamemobile.$errors.length,
}])
                                            }, null, 8, ["onInput", "defaultCountry", "inputOptions", "value", "class"])
                                          ])
                                        ])
                                      ]))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("div", _hoisted_20, [
                                    _withDirectives(_createElementVNode("input", {
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.signIn.password) = $event)),
                                      name: "password",
                                      inputmode: "text",
                                      type: !_ctx.signIn.showPassword ? 'password' : 'text',
                                      autocomplete: "password",
                                      placeholder: _ctx.t('login.password'),
                                      class: _normalizeClass(["form-control", {
                              'is-invalid': _ctx.vSignIn.password.$errors.length,
                            }])
                                    }, null, 10, _hoisted_21), [
                                      [_vModelDynamic, _ctx.signIn.password]
                                    ]),
                                    _createVNode(_component_ion_button, {
                                      onClick: _cache[4] || (_cache[4] = ($event: any) => (
                              _ctx.signIn.showPassword = !_ctx.signIn.showPassword
                              )),
                                      fill: "clear",
                                      slot: "end",
                                      class: "endIconBtn"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_icon, {
                                          slot: "icon-only",
                                          icon: !_ctx.signIn.showPassword
                                  ? _ctx.icons.eyeOutline
                                  : _ctx.icons.eyeOffOutline
                                
                                        }, null, 8, ["icon"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _createElementVNode("div", _hoisted_22, [
                                    (_ctx.signIn.showRememberMe)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                          _createElementVNode("div", _hoisted_24, [
                                            _createVNode(_component_ion_checkbox, {
                                              onIonChange: _cache[5] || (_cache[5] = ($event: any) => (
                                  _ctx.signIn.persistent_remember_me =
                                  !_ctx.signIn.persistent_remember_me
                                  )),
                                              value: 1,
                                              checked: _ctx.signIn.persistent_remember_me
                                            }, null, 8, ["checked"]),
                                            _createElementVNode("label", _hoisted_25, [
                                              _createElementVNode("span", null, _toDisplayString(_ctx.t("login.rememberMe")), 1)
                                            ])
                                          ])
                                        ]))
                                      : _createCommentVNode("", true),
                                    _createElementVNode("a", {
                                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showForgotPassword = true)),
                                      class: "text-muted",
                                      href: "javascript:void(0)"
                                    }, _toDisplayString(_ctx.t("login.forgotPassword?")), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_26, [
                                    _createElementVNode("div", _hoisted_27, [
                                      _createElementVNode("div", _hoisted_28, [
                                        _createElementVNode("button", _hoisted_29, _toDisplayString(_ctx.t("login.login")), 1)
                                      ])
                                    ])
                                  ])
                                ], 32)
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.showForgotPassword)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                              _createElementVNode("div", _hoisted_31, [
                                _createElementVNode("div", _hoisted_32, [
                                  _createElementVNode("h1", _hoisted_33, _toDisplayString(_ctx.t("login.forgotPassword?")), 1)
                                ]),
                                _createElementVNode("form", {
                                  method: "post",
                                  onSubmit: _cache[10] || (_cache[10] = ($event: any) => (_ctx.forgotPasswordAction($event)))
                                }, [
                                  (!_ctx.forgotPassword.useMobile)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                        _withDirectives(_createElementVNode("input", {
                                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.forgotPassword.email) = $event)),
                                          name: "email",
                                          inputmode: "email",
                                          type: "text",
                                          autocomplete: "email",
                                          placeholder: _ctx.t('login.email'),
                                          class: _normalizeClass(["form-control", {
                              'is-invalid':
                                _ctx.vforgotPassword.email.$errors.length,
                            }])
                                        }, null, 10, _hoisted_35), [
                                          [_vModelText, _ctx.forgotPassword.email]
                                        ])
                                      ]))
                                    : _createCommentVNode("", true),
                                  (_ctx.forgotPassword.useMobile)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                                        _createVNode(_component_vue_tel_input, {
                                          onInput: _ctx.onTelInputForgotPassword,
                                          mode: "international",
                                          defaultCountry: _ctx.countryCode,
                                          inputOptions: { placeholder: _ctx.t('login.mobile') },
                                          class: _normalizeClass(["form-control", {
                              'is-invalid':
                                _ctx.vforgotPassword.mobileNo.$errors.length,
                            }])
                                        }, null, 8, ["onInput", "defaultCountry", "inputOptions", "class"])
                                      ]))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("div", _hoisted_37, [
                                    _createElementVNode("div", _hoisted_38, [
                                      _createElementVNode("button", _hoisted_39, _toDisplayString(_ctx.t("login.submit")), 1),
                                      _createElementVNode("a", {
                                        class: "text-muted backToLogin",
                                        href: "javascript:void(0)",
                                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showForgotPassword = false))
                                      }, _toDisplayString(_ctx.t("login.backToLogin")), 1)
                                    ])
                                  ])
                                ], 32)
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      (_ctx.socialEnabled)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                            _createElementVNode("div", _hoisted_41, [
                              _createElementVNode("div", _hoisted_42, [
                                _createElementVNode("div", _hoisted_43, [
                                  _createElementVNode("h1", _hoisted_44, _toDisplayString(_ctx.t("login.continueWith")), 1)
                                ]),
                                _createElementVNode("div", _hoisted_45, [
                                  (_ctx.facebookLoginUrl)
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 0,
                                        class: "socialBtn facebook",
                                        href: _ctx.facebookLoginUrl
                                      }, [
                                        _createVNode(_component_ion_icon, {
                                          slot: "icon-only",
                                          icon: _ctx.icons.logoFacebook
                                        }, null, 8, ["icon"])
                                      ], 8, _hoisted_46))
                                    : _createCommentVNode("", true),
                                  (_ctx.googleLoginUrl)
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 1,
                                        class: "socialBtn google",
                                        href: _ctx.googleLoginUrl
                                      }, [
                                        _createVNode(_component_ion_icon, {
                                          slot: "icon-only",
                                          icon: _ctx.icons.logoGoogle
                                        }, null, 8, ["icon"])
                                      ], 8, _hoisted_47))
                                    : _createCommentVNode("", true),
                                  (_ctx.appleLoginUrl)
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 2,
                                        class: "socialBtn apple",
                                        href: _ctx.appleLoginUrl
                                      }, [
                                        _createVNode(_component_ion_icon, {
                                          slot: "icon-only",
                                          icon: _ctx.icons.logoApple
                                        }, null, 8, ["icon"])
                                      ], 8, _hoisted_48))
                                    : _createCommentVNode("", true)
                                ])
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("footer", _hoisted_49, [
            _createVNode(_component_MainFooter)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}